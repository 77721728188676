<template>
  <div class="dialog-wrapper">
    <dialog open class="dialog" @click.prevent="handleDialog">
      <h2 class="title fL">提示</h2>
      <div class="fM text-center">
        目前是最新版本
      </div>
      <div class="buttons">
        <button class="fM rounded" @click="justClose">確認</button>
      </div>
    </dialog>
  </div>
</template>

<script>
export default {
  name: 'AppUpdateDialog',
  methods: {
    handleDialog(e) {
      e.stopPropagation();
    },
    justClose() {
      this.$emit('close');
    }
  },
};
</script>

<style lang="scss" scoped>
.dialog-wrapper {
  position: fixed;
  inset: 0;
  z-index: 100;
  background-color: #000000CC;
  height: 100svh;
  width: 100svw;

  .dialog {
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    border: none;
    border-radius: 20px;
    max-width: calc(100vw - 64px);
    width: 360px;
    margin: 0;
  }

  .title {
    text-align: center;
    margin-bottom: 16px;
  }

  .buttons {
    display: flex;
    justify-content: center;
    gap: 12px;
    margin-top: 16px;

    button {
      border-radius: 16px;
      color: #fff;
      background-color: var(--k1);
      border: 1px solid var(--k1);
      width: 100%;
      height: 40px;
      cursor: pointer;

      &.button-outline {
        color: var(--k1);
        background-color: #fff;
      }
    }
  }
}
</style>